import styled from "@emotion/styled";

const HeroPortfolioContent = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  // height: 43000px;
  min-height: 80vh;
`;

export default HeroPortfolioContent;
